import React, { useState } from "react";
import { Col, Row, Inline, Block, Grid } from "jsxstyle";
import { addStyleToHead } from "jsxstyle-utils";
import { ContainerQuery } from "react-container-query";
import SamHead from "./images/sam.png";
import DoverSerifRegular from "./fonts/DoverSerifText-Regular.otf";
import {Transition} from 'react-transition-group';

const fadeDuration = 200;

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 }
};

addStyleToHead(`
@font-face {
  font-family: DoverSerif;
  src: url("${DoverSerifRegular}") format("opentype");
}
`);

const Link = ({ url, children }) => (
  <Inline component={"a"} props={{ href: url }}>
    {children}
  </Inline>
);

const IconLink = ({ icon, url }) => (
  <Block
    component={"a"}
    props={{ href: url }}
    marginRight={10}
    hoverTransform={"scale(1.1, 1.1) rotate(9deg)"}
    transition={"all 100ms"}
  >
    <img width={30} src={icon} alt={url} />
  </Block>
);

const links = [
  {
    url: "mailto:gwilym@me.com",
    icon: require("./images/email.png")
  },
  {
    url: "https://twitter.com/sgwil",
    icon: require("./images/twitter.png")
  },
  {
    url: "https://github.com/sgwilym",
    icon: require("./images/github.png")
  }
];

const projects = [
  {
    title: "A programming atelier",
    url: "https://youtu.be/sSVQaWDqIak",
    color: "#ffffff",
    bgColor: "#000000",
    description:
      "A five minute talk which looks at the working qualities of a glassblowing atelier in France, and compares it with exciting projects that bring programming into physical space, namely Dynamicland. I presented this talk at React Europe 2018, and created a dozen or so illustrations especially for it.",
    images: [
      require("./images/glassblow1.jpg"),
      require("./images/glassblow5.jpg"),
      require("./images/glassblow2.jpg"),
      require("./images/glassblow3.jpg"),
      require("./images/glassblow4.jpg")
    ]
  },
  {
    title: "Chloe & Isabel Design System",
    description: `Over the course of two years I authored about a hundred reusable components which helped us put an end to re-implementing designs over and over, wondering how things should look, and untangling style conflicts.

      For our storybook I also created doc-prop-types, a runtime type annotation library that mimics prop-type’s API and also annotates components with information that makes creating automated documentation for React components straightforward.`,
    color: "#f8f8f8",
    bgColor: "#4c6b8b",
    images: [
      require("./images/components0.jpg"),
      require("./images/components1.jpg"),
      require("./images/components2.jpg")
    ]
  },
  {
    title: "Relay Interactive Diagram",
    description:
      "Relay is a data-fetching library that provides a novel solution for interfaces made of nested components. But the problem it attacks is complex enough that as a solution, it’s somewhat complex itself. This interactive diagram maps the moving parts of the now deprecated Relay Classic API, and supplements it with pithy descriptions. Implemented with a mashing of SVG and React.",
    url: "http://sgwilym.github.io/relay-visual-learners/",
    color: "#ffffff",
    bgColor: "#f26b00",
    images: [
      require("./images/relay-visual-1.jpg"),
      require("./images/relay-visual-2.jpg")
    ]
  },
  {
    title: "Comique",
    description:
      "Back in 2014 I started wondering how you could make comics work on phone screens, and how you could make the then fledging in-app-purchase economy work for indie comic makers. I built a publishing platform and iOS reader app that pushed comics made specifically for the small screen. The iOS app fell by the wayside, but a reworking (a web client using React and Relay, querying a Rails-based GraphQL endpoint) still lives on for some brave future day. It's one thing writing software, but nothing will ever beat working with comic makers to help them make something totally new.",
    url: "http://comique.co",
    color: "#ffd743",
    bgColor: "#000000",
    images: [
      require("./images/comique-1.jpg"),
      require("./images/comique-2.jpg"),
      require("./images/comique-3.jpg"),
      require("./images/new-comique-1.jpg"),
      require("./images/new-comique-2.jpg"),
      require("./images/new-comique-3.jpg")
    ]
  },
  {
    title: "Chloe & Isabel Mobile Marketing Center",
    description:
      "This is the first project I was able to use React Native, GraphQL and Relay at scale (and having been launched on 23 March, 2016, I think it’s one of the first apps outside of Facebook to have done so). This opportunity came along when Chloe + Isabel, a jewellery and lifestyle brand, invited me to help them build an app for their merchandisers — one that could help them drive their businesses, get data-rich overviews of all their customers, and act as a indisposable marketing tool. Working alongside a lovely team, I was the design lead for this project, designing and implemeting much of the app and experience, in addition to drawing a new suite of icons for Chloe & Isabel.",
    url: "http://chloeandisabel.com",
    color: "rgb(93, 124, 153)",
    bgColor: "rgb(241, 236, 229)",
    images: [
      require("./images/cplusi-0.jpg"),
      require("./images/cplusi-1.jpg"),
      require("./images/cplusi-2.jpg")
    ]
  },
  {
    title: "Peaches",
    description:
      "Peaches is where my interests in digital design and comics first overlapped. The first chapter of Peaches is a story written in the style of a darkly weird fairytale, presented as a self-scrolling banner with accompanying music by Toby Paramore, and narration by myself. It’s followed up by a pixelated and chiptune-infused comic in a (slightly more) traditional format. Also features typography from Robin Mientjes. Check it out!",
    url: "http://gwil.co/peaches",
    color: "#ff4155",
    bgColor: "#262e46",
    images: [
      require("./images/peaches-1.jpg"),
      require("./images/peaches-2.jpg"),
      require("./images/peaches-3.jpg"),
      require("./images/peaches-4.jpg")
    ]
  },
  {
    title: "Death is Certain",
    description:
      "In Death is Certain, readers not only followed the exploits of hapless adventurers, but also voted on whether the hero should lose hearts or not, driving adversity and eventually the hero's demise. A Ruby Sinatra app featuring a terrifying number of CSS animations, and some icon work that I still feel great about. It was fun while it lasted.",
    url: "http://dice.gwil.co",
    color: "#ffffff",
    bgColor: "#03c364",
    images: [
      require("./images/dice-1.jpg"),
      require("./images/dice-2.jpg"),
      require("./images/dice-3.jpg"),
      require("./images/dice-4.jpg")
    ]
  },
  {
    title: "talking-text",
    description:
      "Ever played one of those old role-playing games? Pokémon? Earthbound? For the Frog the Bell Tolls? Remember when you’d speak to anyone, the text would kind of type itself out, possibly with a little beepy-boopy sound alongside it? This is that effect implemented as an ever-relevant jQuery plugin.",
    url: "http://sgwilym.github.io/talking-text/",
    color: "#d40060",
    bgColor: "#ffe488",
    images: [
      require("./images/talking-text-1.jpg"),
      require("./images/talking-text-2.jpg")
    ]
  },
  {
    title: "The Judge Must Die",
    description:
      "This is a comic I made for Frank Santoro's Comics Course in back in 2013. Composed of 8 spreads, The Judge Must Die is a hazy, vengeful dream laid over a farmowner's telling of his most dubious hire ever. You can read it in its entirety online.",
    url: "http://gwil.co/tjmd",
    color: "#3382fd",
    bgColor: "#f6a8cf",
    images: [
      require("./images/tjmd-1.jpg"),
      require("./images/tjmd-2.jpg"),
      require("./images/tjmd-3.jpg"),
      require("./images/tjmd-4.jpg")
    ]
  },
  {
    title: "pgn",
    description:
      "A short-lived webcomic drawn with markers and colour pencils.",
    url: "http://gwil.co/pgn",
    images: [
      require("./images/pgn-2.jpg"),
      require("./images/pgn-1.jpg"),
      require("./images/pgn-3.jpg")
    ],
    color: "000000",
    bgColor: "#ffffff"
  }
];

const skills = [
  {
    title: "Design",
    skillGroupings: [
      ["Product design", "Project management"],
      ["Interface Design", "Interaction Design"],
      ["Accessibility", "Copywriting"]
    ]
  },
  {
    title: "Programming",
    skillGroupings: [
      ["TypeScript", "JS", "React + Native"],
      ["GraphQL", "Relay", "graphql-ruby"],
      ["CSS", "SVG"]
    ]
  },
  {
    title: "Drawing",
    skillGroupings: [
      ["Illustrations", "Comics", "Icons"],
      ["Ink", "Pastel", "Oils", "Charcoal"],
      ["Figma", "Photoshop", "Illustrator"]
    ]
  },
  {
    title: "Some clients",
    skillGroupings: [
      [
        <Link url={"http://stadiumgoods.com"}>{"Stadium Goods"}</Link>,
        <Link url={"http://unitednations.org"}>{"United Nations"}</Link>
      ],
      [
        <Link url={"http://floorplanner.com"}>{"Floorplanner"}</Link>,
        <Link url={"http://housinganywhere.com"}>{"Housing Anywhere"}</Link>
      ],
      [
        <Link url={"http://philips.com"}>{"Philips"}</Link>,
        <Link url={"http://kidsagainstwar.com"}>{"Kids Against War"}</Link>
      ]
    ]
  }
];

const SkillBreakdown = ({ icon, title, skillGroupings }) => (
  <Col marginBottom={10}>
    <Row marginBottom={5}>
      {icon}
      <Block fontSize={"1.5em"}>{title}</Block>
    </Row>
    {skillGroupings.map((group, i) => (
      <Block key={i} marginBottom={5}>
        {group.map((skill, ix) => (
          <Inline key={ix}>
            {skill}
            {ix !== group.length - 1 && (
              <Inline margin={"0 5px"} color={"rgb(210, 210, 210)"}>
                {"•"}
              </Inline>
            )}
          </Inline>
        ))}
      </Block>
    ))}
  </Col>
);

const AboutMe = () => (
  <Col
    fontFamily={"DoverSerif"}
    padding={"2em"}
    minWidth={"19em"}
    position={"sticky"}
    marginRight={10}
  >
    <Block
      component={"img"}
      props={{ src: SamHead }}
      maxWidth={120}
      marginBottom={15}
    />
    <Block fontSize={"2.5em"} marginBottom={"0.2em"}>
      {"Sam Gwilym"}
    </Block>
    <Block marginBottom={"1em"} lineHeight={"1.5em"}>
      <Block>
        {
          "Hello. I'm interested in design, programming and illustration. I like to work on projects that entertain and inform. I make my living writing software, mostly large front-end projects."
        }
      </Block>
    </Block>

    {skills.map(skill => (
      <SkillBreakdown key={skill.title} {...skill} />
    ))}

    <Row alignItems={"center"}>
      {links.map(link => (
        <IconLink key={link.url} {...link} />
      ))}
    </Row>
  </Col>
);

const Project = ({
  title,
  url,
  description,
  images,
  bgColor,
  color,
  learnedToClick,
  onLearnedToClick
}) => {
  const [imageIndex, setImageIndex] = useState(0);

  return (
    <Col
      fontFamily={"DoverSerif"}
      lineHeight={"1.5em"}
      fontSize={14}
      color={color}
      backgroundColor={bgColor}
      marginBottom={"1.5em"}
      boxShadow={"-1px 3px 8px rgba(0, 0, 0, 0.1)"}
    >
      <Grid
        gridTemplateColumns={"1fr"}
        gridTemplateRows={"1fr"}
        backgroundColor={"white"}
      >
        {images &&
          images.map((imageSrc, i) => (
            <Transition
              key={imageSrc}
              timeout={fadeDuration}
              in={imageIndex === i}
            >
              {transitionState => (
                <Block
                  gridRowStart={0}
                  gridRowEnd={1}
                  gridColumnStart={0}
                  gridColumnEnd={1}
                  component={"img"}
                  props={{
                    src: imageSrc,
                    onClick: () => {
                      setImageIndex(i === images.length - 1 ? 0 : i + 1);
                      onLearnedToClick();
                    }
                  }}
                  transition={`opacity ${fadeDuration}ms linear`}
                  {...transitionStyles[transitionState]}
                  width={"100%"}
                  pointerEvents={
                    ["exiting", "exited"].includes(transitionState)
                      ? "none"
                      : "auto"
                  }
                  cursor={"pointer"}
                />
              )}
            </Transition>
          ))}
        {learnedToClick === false && (
          <Col
            gridRowStart={0}
            gridRowEnd={1}
            gridColumnStart={0}
            gridColumnEnd={1}
            color={"black"}
            alignItems={"flex-end"}
            justifyContent={"flex-end"}
            padding={10}
          >
            <Block
              backgroundColor={bgColor}
              color={color}
              padding={".7em .7em .5em"}
              borderRadius={10}
              boxShadow={"-1px 3px 8px rgba(0, 0, 0, 0.1)"}
              pointerEvents={"none"}
            >
              {"Click to cycle through images"}
            </Block>
          </Col>
        )}
      </Grid>
      <Block padding={"1.5em"} columns={"3 23em"}>
        <Block fontSize={"1.5em"} marginBottom={5}>
          {title}
        </Block>
        {url && (
          <Block
            component={"a"}
            props={{ href: url }}
            color={color}
            marginBottom={5}
          >
            {url}
          </Block>
        )}
        {description}
      </Block>
    </Col>
  );
};

const Projects = () => {
  const [userHasLearnedToClick, setUserHasLearnedToClick] = useState(false);

  return (
    <Col>
      {projects.map(project => (
        <Project
          key={project.title}
          {...project}
          onLearnedToClick={() => {
            setUserHasLearnedToClick(true);
          }}
          learnedToClick={userHasLearnedToClick}
        />
      ))}
    </Col>
  );
};

const Layout = ({ isWide, aboutMe, projects }) =>
  isWide ? (
    <Row position={"sticky"} top={0}>
      {aboutMe}
      {projects}
    </Row>
  ) : (
    <Col>
      {aboutMe}
      {projects}
    </Col>
  );

const App = () => (
  <ContainerQuery query={{ isWide: { minWidth: 800 } }}>
    {({ isWide }) =>
      isWide === undefined ? (
        <div />
      ) : (
        <Layout isWide={isWide} aboutMe={<AboutMe />} projects={<Projects />} />
      )
    }
  </ContainerQuery>
);

export default App;
